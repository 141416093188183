<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <template v-if="dataLoaded">
                <app-button-edit
                  route-name="hotel_edit"
                  :record-id="hotel.id"
                >
                </app-button-edit>
                <app-button-delete-with-usages
                  @deleteRecord="deleteHotel"
                  @load-data="checkUsages"
                  @page-change="setUsagesPageAndGetRecords"
                  :data="usages.data"
                  :total-count="usages.totalCount"
                  :page="usages.page"
                  :showLoader="usages.showLoader"
                  :config="usagesDataTableConfig"
                  :showDeleteButton="isAllowedToDelete"
                ></app-button-delete-with-usages>
              </template>
              <app-button-close route-name="hotel_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div v-if="dataLoaded" class="card">
        <div class="card-body">
          <table class="table table-bordered table-hover">
            <tbody>
            <tr>
              <th>{{ $t('hotel.id') }}</th>
              <td>{{ hotel.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.title') }}</th>
              <td>{{ hotel.title }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.description') }}</th>
              <td>{{ hotel.description }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.name') }}</th>
              <td>{{ hotel.name }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.image') }}</th>
              <td>
                <app-media
                  v-if="hotelImage"
                  :media="hotelImage"
                  :width="480"
                  :height="270"
                  show-media-info
                >
                </app-media>
              </td>
            </tr>
            <tr>
              <th>{{ $t('hotel.yearOfReview') }}</th>
              <td>{{ hotel.yearOfReview }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.address') }}</th>
              <td>
                <div v-if="this.hotel.expanded.address">
                  {{ this.hotel.expanded.address.street }}<br>
                  {{ this.hotel.expanded.address.expanded.city.title }}<br>
                  {{ this.hotel.expanded.address.expanded.district.title }}<br>
                  {{ this.hotel.expanded.address.expanded.country.title }}<br>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('hotel.phone') }}</th>
              <td>{{ hotel.phone }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.website') }}</th>
              <td>{{ hotel.website }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.priceLevel') }}</th>
              <td>{{ hotel.priceLevel }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.hotelStars') }}</th>
              <td>{{ hotel.hotelStars }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.rating') }}</th>
              <td>{{ hotel.rating }}</td>
            </tr>
            <tr>
              <th>{{ $t('hotel.pros') }}</th>
              <td>
                <ul class="list-unstyled">
                  <li
                    v-for="(value, index) in hotel.prosCons.pros"
                    :key="`item-${index}`"
                  >
                    <i class="fas fa-plus text-danger"></i>
                    {{ value }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{{ $t('hotel.cons') }}</th>
              <td>
                <ul class="list-unstyled">
                  <li
                    v-for="(value, index) in hotel.prosCons.cons"
                    :key="`item-${index}`"
                  >
                    <i class="fas fa-minus text-success"></i>
                    {{ value }}
                  </li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
          <app-detail-system-rows :record="hotel"></app-detail-system-rows>
        </div>
      </div>
      <div v-else>
        <app-preloader></app-preloader>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Preloader from '../../components/preloader/Preloader'
import ButtonClose from '../../components/shared/ButtonClose'
import ButtonDeleteWithUsages from '../../components/shared/ButtonDeleteWithUsages'
import ButtonEdit from '../../components/shared/ButtonEdit'
import DetailSystemRows from '../../components/shared/DetailSystemRows'
import Media from '../../components/shared/Media'
import HotelModel from '../../model/HotelModel'
import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'HotelView',
  data () {
    return {
      hotel: HotelModel,
      error: null,
      dataLoaded: false,
      usages: {},
      usagesDataTableConfig: {
        fields: {
          'field.title': this.$t('article.list.title'),
          'setting.status': this.$t('article.list.status'),
          orderDate: this.$t('article.list.order_date')
        },
        actions: {
          copyToClipboard: 'documentId',
          detail: 'article_detail'
        }
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonDeleteWithUsages: ButtonDeleteWithUsages,
    appButtonEdit: ButtonEdit,
    appButtonClose: ButtonClose,
    appDetailSystemRows: DetailSystemRows,
    appMedia: Media,
    appPreloader: Preloader
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isAllowedToDelete () {
      if (this.currentUser.roles.includes(ROLE_SUPER_ADMIN)) {
        return true
      }
      return false
    },
    hotelImage () {
      return this.$store.getters['hotel/image']
    }
  },
  methods: {
    getHotel () {
      this.$store.dispatch('hotel/fetchOne', this.$route.params.id)
        .then(() => {
          this.dataLoaded = true
          this.hotel = this.$store.getters['hotel/detail']
        })
    },
    checkUsages () {
      this.$store.commit('hotel/setUsagesId', this.hotel.id)
      this.usages = { page: 1, totalCount: 0, data: [], showLoader: true }
      this.getUsagesList()
    },
    getUsagesList () {
      this.usages.showLoader = true
      this.$store.dispatch('hotel/fetchUsages')
        .then(() => {
          this.usages.data = this.$store.getters['hotel/usagesList']
          this.usages.totalCount = this.$store.getters['hotel/usagesTotalCount']
          this.usages.page = this.$store.getters['hotel/usagesPage']
          this.usages.showLoader = false
        })
    },
    setUsagesPageAndGetRecords (page) {
      this.$store.commit('hotel/setUsagesPage', page)
      this.getUsagesList()
    },
    deleteHotel () {
      if (this.usages.totalCount > 0) {
        NotifyService.setErrorMessage(this.$t('notify.record_not_deleted'))
        return
      }
      this.$store.dispatch('hotel/deleteRecord', this.hotel)
        .then(() => {
          if (this.$store.getters['hotel/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/articleTest/hotel')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['hotel/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  mounted () {
    this.getHotel()
  }
}
</script>

<style lang="scss" scoped>
.table-bordered th:first-child {
  width: 200px;
}
</style>
